.topbar{
  display: inline-block;

  .navbar{
    color: white !important;
  }
  img {
    height:50px;
    // height:auto;
  }
}

svg{
  color: maroon !important;
}

