.paymentListItem{
  background: inherit;
  color: inherit;

  .card-title{
    color: maroon;
    font-weight: bold;
  }

  .card-text{
    color: maroon;
  }
}