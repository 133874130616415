.offeredServices{
  .home-title{
    font-size: 50px;
    font-weight: 900;
  }

  .trusted{
    background-color: whitesmoke;
    p{
      font-size: medium;
      color: maroon !important;
    }
  }

  .trusted-title{
    font-size: 40px;
    font-weight: 900;
  }

  a:link, a:visited,  a:hover, a:active{
    text-decoration: none !important;
  }

}