.testimonials{
  .primary-buttons{
    justify-content: space-around !important;
    text-align: center;
  }

  .testimonials{
    h3{
      color: maroon;
    }
    p{
      color: black;
      font-size: medium;
    }
  }
  
  .card{
    border-color: transparent !important;
    .card-title{
      font-size: x-large;
      font-weight: bold;
    }
  }

  .card-text{
    font-size: medium;
  }

}