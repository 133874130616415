.slideshow{
  .primary-buttons{
    justify-content: space-around !important;
    text-align: center;
  }

  .testimonials{
    h3{
      color: maroon;
    }
    p{
      color: black;
      font-size: medium;
    }
  }
  .card{
    border-color: transparent !important;
  }

  .card-text{
    font-size: medium;
  }

  iframe{
    height: 50%;
    width: 100%;
  }

}