.movingPackages{
  background: white;
  color: black;

  .home-title{
    color: maroon;
    font-size: 50px;
    font-weight: 900;
  }
  p{
    font-size: x-large;
    position:relative;
    background: white;
    color: maroon;
  }

  h5{
    color: maroon;
    font-weight:900;
  }

  h6{
    color: maroon;
  }
  button{
    background-color: maroon;
    color: white !important;
  }
  .btn{
    color: white !important;
  }
  iframe {
    display: block;       /* iframes are inline by default */
    height: calc(100vh - 300px);        /* Viewport-relative units */
    width: 100vw;
  }
}
